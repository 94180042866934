import axios, { AxiosRequestConfig } from 'axios';
import { Identity } from 'models/Identity';
import * as storage from "../../context/Storage";

export const intercept = () => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    return setUserHeaders(config);
  }, (error) => {
    return Promise.reject(error);
  });
}

export const setUserHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {

  config.params && Object.keys(config.params).forEach((key) => {
    if (config.params[key] === '' || config.params[key] === undefined) {
      delete config.params[key];
    }
  });

  config.headers.Authorization = `Bearer ${(storage.local.getItem("accessToken") || "").trim()}`;;

  const idtoken = storage.local.getItem("id_token");

  if (idtoken) {
    const id: Identity = JSON.parse(idtoken);
    const isValidIdentity: boolean = (id.scopeUser !== undefined && id.scopeRegion !== undefined);

    if (id.isAuthenticated && !isValidIdentity) {
      storage.local.removeItem("id_token");
      storage.local.removeItem("accessToken");
      storage.local.removeItem("userPreferences");
      console.error("Invalid region path login out")
      throw "Invalid region path";
    }
  }
  return config;
};
