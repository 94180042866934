import { InputSearch, InputSearchEntity, InputSearchGroup, InputSearchOption } from "components/InputSearch/InputSearch";
import { useHeaderSearchContext } from "context/HeaderSearchProvider";
import { useAppState } from "context/StateProvider";
import { SearchHelper } from "helpers/SearchHelper";
import { useHeaderSearch } from "hooks/CommandCenter/useHeaderSearch";
import { ISearchResult } from "models/Search/SearchResult";
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as SpringGlobalTextLogo } from "./../../assets/icons/svg/spring-global-text-logo.svg";
import { useDebouncedCallback } from "use-debounce";
import './HeaderV2.scss';
import { useQuery } from "react-query";
import { SearchService } from "services/general/SearchService";
import { ISearchConfig } from "models/Search/SearchConfig";
import { Resources } from "../../resources/Resources";
import { Icon } from "components/Icon/Icon";
import { BetaTest } from "components/BetaTest/BetaTest";
import { UsersService } from "services/UsersService";
import { Skeleton } from "@mui/material";

const DELAY_TYPE_SEARCH = 500;

interface HeaderV2UserMenuProps {
    updateBetaOption: (param?: any) => any;
}

const HeaderV2UserMenu: FunctionComponent<HeaderV2UserMenuProps> = (props) => {
    const { updateBetaOption } = props;
    const { identity } = useAppState();

    const userInitials = useMemo(() => identity?.id?.substring(0, 2).toLocaleUpperCase(), [identity])

    const [betaFeatureVisible, setBetaFeatureVisible] = useState<boolean>(false);
    const ref = useRef(null);

    const [loadingBeta, setLoadingBeta] = useState<boolean>(true);
    const [betaPreferences, setBetaPreferences] = useState<Object>({})

    useEffect(()=>{
        if(identity?.id)
          UsersService.getUserPreferences(identity.id).then((result: any)=>{
            setLoadingBeta(false)
            setBetaPreferences(result.beta)
        })
    },[identity])

    const handleShowBetaModal = () =>{
        if(loadingBeta === false)
            setBetaFeatureVisible(!betaFeatureVisible)
    }
    
    return (
        <>
            <div className="HeaderV2UserMenu">
                <div ref={ref} className={`HeaderV2UserMenu__beta ${betaFeatureVisible ? 'betaVisible' : 'betaInvisible'}`} onClick={handleShowBetaModal}>
                    {loadingBeta == true ? 
                    <Skeleton variant="circular" width={40} height={40}></Skeleton>
                    :
                    <Icon name="construction" />
                    }
                </div>
                <div className="HeaderV2UserMenu__icon">
                    <span className="HeaderV2UserMenu__icon-initials">{userInitials}</span>
                </div>
            </div>
            <BetaTest 
                modalVisible={betaFeatureVisible}
                alertVisible={false}
                onBlur={()=>{setBetaFeatureVisible(false)}}
                iconRef={ref}
                onUpdateBetaOption={updateBetaOption}
                loading={loadingBeta}
                betaPreferences={betaPreferences}
            />
        </>
    )
}

interface HeaderV2AutocompleteSearchProps { }

const HeaderV2AutocompleteSearch: FunctionComponent<HeaderV2AutocompleteSearchProps> = (props) => {
    const history = useHistory();

    const [searchText, setSearchText] = useState<string>('');
    const [continuationToken, setContinuationToken] = useState<string | undefined>();
    const { onSelectOption: setSelectedOption } = useHeaderSearchContext();

    const [baseData, setBaseData] = useState<InputSearchGroup[]>([]);
    const [data, setData] = useState<InputSearchGroup[]>([]);
    const [searchConfig, setSearchConfig] = useState<ISearchConfig[]>([]);
    const [globalLoading, setGlobalLoading] = useState<boolean>(false);
    const { data: configData, isLoading: isLoadingUserConfig, error } = useQuery(['searchConfig'], () => SearchService.getSearchConfig());
    const { search: searchResult, setQuerySearch, querySearch } = useHeaderSearch({ minLength: 3, searchConfig, token: continuationToken });
    const { data: rawData, isLoading: isLoadingUserSearch } = searchResult;
    const disableSidebar = configData == undefined;
    const [alertMessage, setAlertMessage] = useState<string>();

    useEffect(() => {
        if (configData) {
            setSearchConfig(configData)
            setBaseData(configData.reduce((acc: InputSearchGroup[], curr) => {
                if (curr.status === true) {
                    acc.push(SearchHelper.mapSearchGroup(curr.name))
                }
                return acc;
            }, []))
        }
    }, [configData])

    useMemo(() => {
        const tmp = baseData;
        rawData?.map(searchResult => {
            searchResult.result?.then((res) => {
                const searchData: ISearchResult = res.data as ISearchResult;
                const itemTmp = tmp.find(x => x.label.toLowerCase() === searchResult.name.toLowerCase())!;
                itemTmp.options = SearchHelper.mapSearchOptions(searchData.value.results, searchResult.name as InputSearchEntity, searchResult.idField);
                itemTmp.continuationToken = searchData.value.continuationToken;
                setData(tmp)
            }).
                finally(() => {
                    setGlobalLoading(false);
                })
        })
    }, [rawData]);

    useEffect(() => {
        if (searchResult.dataUpdatedAt > 0)
            setGlobalLoading(isLoadingUserConfig);
    }, [querySearch])

    const debouncedSearch = useDebouncedCallback(
        (codeSearch: string) => {
            setContinuationToken(undefined);
            setQuerySearch(codeSearch.replace(/\s+/g, ' '))
        }, DELAY_TYPE_SEARCH
    );

    const handleSearchTextchange = ({ nativeEvent, target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (value.length > 0 && value.length < 3) {
            setAlertMessage("Please enter a minimum of 3 characters to search");
        } else if (value.length > 2) {
            setGlobalLoading(true);
            debouncedSearch(value);
            setSearchText(value);
            setAlertMessage(undefined);
        } else {
            handleSearchTextClear(value);
        }
    }

    const handleSearchTextClear = (value: string) => {
        setGlobalLoading(false);
        setSearchText(value);
        setQuerySearch("");
        debouncedSearch.cancel();
        setAlertMessage(undefined);
        setData([]);
    }

    const handleOptionSelect = (option: InputSearchOption) => {
        setSelectedOption(option);
        switch (option.entity) {
            case "customer":
                history.push({
                    pathname: `/View360/${option.itemId}`,
                    state: { "itemId": option.itemId }
                })
                break;
        }
    }

    const handleShowMore = (token: string) => {
        setGlobalLoading(true);
        setContinuationToken(token);
    }

    return (
        <div className="HeaderV2AutocompleteSearch">
            <InputSearch
                className="HeaderV2AutocompleteSearch__input"
                name="filter"
                placeholder={Resources.get("CommandCenter", "searchBar", "placeholder")}
                value={searchText}
                optionsGroups={data}
                isLoading={globalLoading || isLoadingUserConfig}
                onChange={handleSearchTextchange}
                onClear={handleSearchTextClear}
                onOptionSelect={handleOptionSelect}
                onShowMore={handleShowMore}
                disabled={disableSidebar}
                errorMessage={error}
                alertMessage={alertMessage}
            />
        </div>
    )
}

interface HeaderV2Props {
    middleComponents?: React.ReactChildren;
    rightComponents?: React.ReactChildren;
    className?: string;
    updateBetaOption: (param?: any) => any;
}

const HeaderV2: FunctionComponent<HeaderV2Props> = (props) => {
    const {
        middleComponents,
        rightComponents,
        className,
        updateBetaOption
    } = props

    const _middleComponents = useMemo(() => middleComponents || React.createElement(HeaderV2AutocompleteSearch), [middleComponents]);
    const _rightComponent = useMemo(() => rightComponents || React.createElement(HeaderV2UserMenu, {updateBetaOption}), [rightComponents]);
    const history = useHistory();

    const handleLogoAction = () => {
        history.push({ pathname: "/" })
    }

    return (
        <div className={`HeaderV2 ${className}`}>
            <SpringGlobalTextLogo onClick={handleLogoAction} className=" HeaderV2__left-section HeaderV2__spring-logo" />
            <div className="HeaderV2__middle-section">{_middleComponents}</div>
            <div className="HeaderV2__right-section">{_rightComponent}</div>
        </div>
    );
}

export default HeaderV2;