import React from "react";
import {
  HeaderAction,
  HeaderSecondaryAction,
} from "../../models/actions/Header";
import "./Header.scss";
const Button = React.lazy(() => import ("react-bootstrap/Button"));

export interface HeaderProps {
  title: string;
  actions?: HeaderAction[];
  secondaryActions?: HeaderSecondaryAction[];
  beta?: boolean;
}

export const Header = (props: HeaderProps) => {
  const { title, actions = [], secondaryActions = [], beta } = props;
  return (
    <div className="Header">
      <div className="HeaderLeft">
        <>
          <h2 className="HeaderLeft__title">{title}</h2>
          { beta === true &&
            <div className="HeaderLeft__beta">
              <span>BETA</span>
            </div>
          }
        </>
        <div className="HeaderLeft__Actions">
          {actions.map(({ name, callback }, index) => (
            <Button
              className="action"
              key={index}
              onClick={callback}
              variant="main-action"
            >
              {name}
            </Button>
          ))}
        </div>
      </div>
      <div className="HeaderCenter"/>
      <div className="HeaderRight">
        <div className="HeaderOption">
          {secondaryActions.map(({ element, isToggle }, index) => (
            <div
              className={`Option ${isToggle ? "Option--toggle" : ""}`}
              key={index}
            >
              {element}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
