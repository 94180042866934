import jwt from 'jsonwebtoken';
import React, { useState } from "react";
import * as storage from "../context/Storage";

export default function useAccessToken(accessLevel?: string) {
    const [accessToken, setAccessToken] = useState(storage.local.getItem("accessToken"));
    const decodedToken: any = React.useMemo(() => jwt.decode(accessToken || "", { complete: true }) || {}, [accessToken]);
    const expired = React.useMemo(() => Number(decodedToken?.payload?.exp || 0) < Math.floor(Date.now() / 1000), [decodedToken]);

    const hasAccessLevel = React.useCallback((_accessLevel?: string) => {
        const a = _accessLevel || accessLevel;
        return !a || (decodedToken.payload.AccessLevels || []).includes(a);
    }, [decodedToken, accessLevel]);

    return { expired, hasAccessLevel, setAccessToken };
}